import React from 'react';
import './footer.css';
import data from './data.json';
import { links } from '../../data/data.json';

export default function Footer() {
    const { baseAppImgUrl } = links;
    return (
        <div className="footer">
            <img className="secret-shiba-word-logo" src={`${baseAppImgUrl}/seekr/secretShibaWord.png`} alt="logo" />
            <div className="footer-boxes">
            {
                data.map(list => (
                    <div className="footer-box" key={list.title}>
                        <div className="footer-title">
                            {list.title}
                        </div>
                        {list.items.map(item => (
                            <a className="footer-text" href={item.url} key={item.url} target="_blank" rel="noreferrer">
                                {item.name}
                            </a>
                        ))}
                    </div>
                ))
            }
            </div>
        </div>
    );
}
