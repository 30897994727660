import React, { useState } from 'react';
import './appIntro.css';
import { links } from '../../../data/data.json';

export default function AppIntro() {
    const { baseImgUrl } = links;
    const appIntros = {
        first: {
            text: 'EXPLORE TOKENS',
            image: `${baseImgUrl}/app-intros/second.png`
        },
        second: {
            text: 'FIND CONNECTIONS',
            image: `${baseImgUrl}/app-intros/first.png`
        },
        third: {
            text: 'UPDATE',
            image: `${baseImgUrl}/app-intros/third.png`
        }
    };
    const [selected, setSelected] = useState(appIntros.second.image);
    const handleClick = (value) => {
        setSelected(appIntros[value].image);
    };
    return (
        <>
            <div className="app-intro-selects">
                {Object.keys(appIntros).map(key => (
                    <div className="select-container" key={key}>
                        <button className="select-btn" onClick={() => handleClick(key)}>
                            {appIntros[key].text}
                        </button>
                    </div>
                ))}
            </div>
            <br />
            <img src={selected} alt="App Intro" className="app-intro-img" />
        </>
    );
}
