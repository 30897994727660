import React from 'react';
import './header.css';
import ParticlesBg from 'particles-bg';
import SeekrLink from '../Navbar/MainMenu/SeekrLink/SeekrLink';
import { main, links } from '../../data/data.json';

export default function Header() {
    const { headerText, headerSubtext } = main;
    const { baseImgUrl } = links;
    return (
        <>
            <div className="header">
                <div className="headliner">
                    <div className="text-container">
                        <div className="headliner-text">
                            { headerText }
                        </div>
                        <br />
                        <div className="headliner-subtext">
                            { headerSubtext }
                        </div>
                    </div>
                    <div className="launch-app-header">
                        <SeekrLink />
                    </div>
                </div>
                <div className="mockup">
                    <img className="mockup-img" src={`${baseImgUrl}/SeekrMockup.png`} alt="Logo" />
                </div>
            </div>
            <div className="particles">
                <ParticlesBg type="cobweb" color="#F9E46C" bg num={15} />
            </div>
        </>
    );
}
